import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions } from './store/login-slice';
import Spinner from '../app/shared/Spinner';


const Dashboard = lazy(() => import('./pages/Dashboard'));
const Login = lazy(() => import('./user-pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const LandingPage = lazy(() => import('./swayamPlus/LandingPage'));
const FetchSteps = lazy(() => import('./swayamPlus/FetchSteps'));
const Page = lazy(() => import('./swayamPlus/CoursePage'));
const SSOLogin = lazy(() => import('./user-pages/LoginHome'));
const Certificate = lazy(() => import('./swayamPlus/Certificate'))
const Chat = lazy(() => import('../app/swayamPlus/Chat'))
const Privacy = lazy(() => import('./pages/Privacy'))
const CookieConsentPolicy =lazy(()=>import('./pages/CookieConsentPolicy'))

const AppRoutes = () => {
  const isLogin = useSelector((state) => state.login.isLogin);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateToken = async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          const hasValidToken = Boolean(localStorage.getItem('access_token'));
          resolve(hasValidToken);
        }, 1000);
      });
    };

    const checkLoginStatus = async () => {
      const hasValidToken = await validateToken();
      if (hasValidToken) {
        dispatch(loginActions.setLogin());
      } else {
        dispatch(loginActions.setLogout());
      }
      setIsLoading(false);
    };

    checkLoginStatus();
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/">

          <Redirect to="/login" />
        </Route>

        <Route path="/ssologin">
          {isLogin ? <Redirect to="/dashboard" /> : <SSOLogin />}
        </Route>

        <Route path="/dashboard">
          {isLogin ? <LandingPage /> : <Redirect to="/ssologin" />}
        </Route>

        <Route path="/fetchsteps">
          {isLogin ? <FetchSteps /> : <Redirect to="/ssologin" />}
        </Route>
        <Route path="/certificate">
          {isLogin ? <Certificate /> : <Redirect to="/ssologin" />}
        </Route>
        <Route path="/chat">
          {isLogin ? <Chat /> : <Redirect to="/ssologin" />}
        </Route>

        <Route path="/page">
          <Page />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/cookie">
          <CookieConsentPolicy />
        </Route>
        <Route path="/login">
          <Login />
        </Route>

        <Route exact path="/logout">
          <Logout />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
